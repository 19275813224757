import { userStore } from '@/store/modules/user/user-store';
import { globalStore } from '@/store/modules/global/global-store';

export default {
  login(options: { openLoginDialog?: boolean; refresh?: boolean } = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      const finalOptions = Object.assign(
        {},
        {
          openLoginDialog: true,
          refresh: false,
        },
        options
      );
      Promise.resolve(
        !finalOptions.refresh ? userStore.dispatch('userLoginFromCache') : Promise.reject()
      )
        .then(() => {
          resolve();
        })
        .catch(() => {
          if (finalOptions.openLoginDialog) {
            this.loginFromDialog()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          } else {
            reject();
          }
        });
    });
  },

  loginFromDialog(): Promise<void> {
    return new Promise((resolve, reject) => {
      globalStore.commit('showLoginDialog', {
        page: 1,
        resolve: resolve,
        reject: reject,
      });
    });
  },
  // 匿名登陆
  async userLoginForAnonym() {
    return userStore.dispatch('userLoginForAnonym');
  },
};
