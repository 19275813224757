import http from '@/utils/http';

export default {
  // 抠物
  photoCutout(params: { url: string }): Promise<{ url: string }> {
    return http.post('/sys/smart_cutout', params);
  },

  // 填充，擦除笔和去水印都是用的这个
  photoFill(params: FormData): Promise<{ url: string }> {
    return http.post('/sys/smart_fill', params);
  },

  // 人脸提清
  faceEnhance(params: { url: string }): Promise<{ url: string }> {
    return http.post('/sys/face_enhance', params);
  },

  // 抠人像
  photoPortrait(params: { url: string }): Promise<{ url: string }> {
    return http.post('/sys/body_segment', params);
  },

  submitOrder(params: {
    org_url: string;
    process_url: string;
  }): Promise<{ order_id: number; pay_amount: number }> {
    return http.post('/order_submit', params);
  },

  orderPay(params: { order_id: number; pay_type_id: number }): Promise<void> {
    return http.post('/order_pay', params);
  },
};
