import { globalStore } from '@/store/modules/global/global-store';
import { userStore } from '@/store/modules/user/user-store';
import userService from './user-service';
import payConfirm from '@/components/pay/pay-confirm/index';
import eventTrack from '@/event-track';
import modulesApi from '@/apis/modules-api';
import idphotoApi from '@/apis/idphoto-api';
import type { RechargeDialogSinglePayInfo } from '@/store/modules/global/global-type';

export default {
  // 充值
  showRechargeDialog(options?: {
    category_id?: number;
    singlePayInfo: RechargeDialogSinglePayInfo;
  }) {
    return new Promise<{ isSinglePaySuccess: boolean }>((resolve, reject) => {
      if (options?.category_id) {
        eventTrack.show_recharge_by_point_lack({ category_id: options.category_id });
      } else {
        eventTrack.show_recharge_by_initiative();
      }
      globalStore.commit('showRechargeDialog', {
        singlePayInfo: options?.singlePayInfo,
        resolve: resolve,
        reject: reject,
      });
    });
  },

  // 证件照的订单支付
  async idphotoOrderPay(data: {
    price: number;
    category_id: number;
    showPayConfirm?: boolean;
    singlePayInfo: RechargeDialogSinglePayInfo;
  }): Promise<void> {
    if (!userStore.getters.isLogin) {
      // 未登录，直接使用匿名登陆
      await userService.userLoginForAnonym();
    }

    // 更新余额
    await userStore.dispatch('getUserInfo');

    const currPoint = userStore.state.userInfo?.total_point || 0;

    if (data.price > currPoint) {
      // 点数不够，显示充值弹窗
      const { isSinglePaySuccess } = await this.showRechargeDialog({
        category_id: data.category_id,
        singlePayInfo: data.singlePayInfo,
      });
      if (isSinglePaySuccess) {
        // 单次支付成功,则直接返回成功
        return;
      } else {
        // 点数充值成功, 再次确认信息
        return this.idphotoOrderPay(data);
      }
    } else {
      // 点数够,直接支付
      // 支付订单
      await idphotoApi.orderPay({ order_id: data.singlePayInfo.order_id, pay_type_id: 1 });
    }

    return;
  },
  // 模块的订单支付
  async moduleOrderPay(data: {
    price: number;
    category_id: number;
    showPayConfirm?: boolean;
    singlePayInfo: RechargeDialogSinglePayInfo;
  }) {
    if (!userStore.getters.isLogin) {
      // 未登录，直接使用匿名登陆
      await userService.userLoginForAnonym();
    }

    // 弹出支付确认
    const { isSinglePaySuccess } = await payConfirm.show(
      data.price,
      data.category_id,
      data.singlePayInfo
    );
    if (isSinglePaySuccess) {
      // 单次支付成功,则直接返回成功
      return;
    } else {
      // 点数充值成功, 调用支付
      await modulesApi.orderPay({ order_id: data.singlePayInfo.order_id, pay_type_id: 1 });
    }

    return;
  },
};
