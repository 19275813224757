import http from '@/utils/http';
import type {
  IGoodsItem,
  IHomePageData,
  IGenImageProcessResult,
  IGenClothesProcessResult,
} from '@/types/idphoto';
import { ranString } from '@/utils/utils';
import { AxiosRequestConfig } from '@/types/shims.axios';

export default {
  goodsSearch(str: string): Promise<{ product_list: IGoodsItem[] }> {
    return http.post('/sys/product_search', {
      condition: str,
    });
  },

  getHotGoods(): Promise<{ product_list: IGoodsItem[] }> {
    return http.post('/sys/hot_product_search', {});
  },

  recordHotGoods(goodsId: number): Promise<void> {
    return http.post('/sys/hot_product_record', {
      id: goodsId,
    });
  },

  getHomePageData(): Promise<IHomePageData> {
    return http.post('/sys/product_homepage', {});
  },

  getGoodsInfo(goods_id: number): Promise<IGoodsItem> {
    return http.post('/sys/product_query', {
      id: goods_id,
    });
  },

  // 生成图片文件
  genImageProcess(
    product_id: number,
    original_key: string,
    options: AxiosRequestConfig
  ): Promise<IGenImageProcessResult> {
    return http.post(
      '/sys/image_process',
      {
        product_id,
        key: original_key,
      },
      options
    );
  },

  //排版照片
  genTypeset(params: {
    fid: string;
    product_id: number;
    bg_clr_id: number;
    need_beautify: number;
    clothes_id?: string;
    need_enhance: number;
  }): Promise<{ url: string }> {
    return http.post('/sys/typeset_make', params);
  },

  // 生成换装图片
  genClothesImageProcess(params: {
    fid: string;
    product_id: number;
    clothes_id: string;
    width_pixel?: number;
    height_pixel?: number;
    use_pixel?: number;
  }): Promise<IGenClothesProcessResult> {
    return http.post('/sys/clothes_assemble', params, {
      timeout: 60000,
      timeoutErrorMessage: '请求超时',
    });
  },

  async getMobileUploadCode(): Promise<{ code: string }> {
    return { code: ranString(32) };
  },
  getMobileUploadImage(code: string): Promise<{ key: string; host: string }> {
    return http.post('/sys/phone_pic_get', { code });
  },

  submitOrder(params: {
    product_id: number;
    bg_clr_id: number;
    clothes_id?: string;
    use_beautify: number;
    fid: string;
  }): Promise<{ order_id: number; pay_amount: number }> {
    return http.post('/order_submit', params);
  },

  orderPay(params: { order_id: number; pay_type_id: number }): Promise<void> {
    return http.post('/order_pay', params);
  },
};
