import dayjs, { Dayjs } from 'dayjs';
import { suffixToMimeMap } from '@/consts/common-map';
import { reject } from 'lodash';

// 获取base64
export function getBase64(img: Blob | File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result as string);
    });
    reader.addEventListener('error', reject);
    reader.readAsDataURL(img);
  });
}

// 转为最多两位小数
export function parseIntoTwoDecimalNumber(value: string): string {
  return value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1');
}

// 转为整数
export function parseIntoOnlyIntNumber(value: string): string {
  return value.replace(/[^\d]/g, '');
}

export function getImageBlobByUrl(url: string): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (this.status == 200) {
        resolve(this.response as Blob);
      }
    };
    xhr.onerror = reject;
    xhr.send();
  });
}

export function getImgInfo(url: string): Promise<HTMLImageElement> {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function () {
      reject();
    };
    img.src = url;
  });
}

export function jsLoad(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const nScript = document.createElement('script');
    nScript.src = url;

    nScript.onload = function () {
      resolve();
      document.head.removeChild(nScript);
    };
    nScript.onerror = function () {
      reject();
    };

    document.head.appendChild(nScript);
  });
}

// 格式化日期
export function formatDate(date: string | Dayjs | Date | number, formatStr = 'YYYY-MM-DD'): string {
  return dayjs(date).format(formatStr);
}

// 判断是否是base64的图片
export function isBase64Img(url: string): boolean {
  return url.startsWith('data:');
}

// base64转Blob
export function base64ToBlob(dataurl: string): Blob | null {
  const arr = dataurl.split(',');
  if (arr.length === 0) return null;

  const strs = arr[0].match(/:(.*?);/);
  if (strs && strs.length > 1) {
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: strs[1] });
  }
  return null;
}

// url转blob
export function urlToBlob(url: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('get', url);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject();
      }
    };
  });
}

// 将对象的元转为分
export function parseYuanToFen(
  object: { [key: string]: unknown },
  fieldNames: Array<string>
): { [key: string]: unknown } {
  for (const key in object) {
    if (fieldNames.includes(key)) {
      object[key] = (object[key] as number) * 100;
    }
  }
  return object;
}

// 将对象的元转为分
export function parseFenToYuan(
  object: { [key: string]: unknown },
  fieldNames: Array<string>
): { [key: string]: unknown } {
  for (const key in object) {
    if (fieldNames.includes(key)) {
      object[key] = (object[key] as number) / 100;
    }
  }
  return object;
}

// 将对象的制定字段转化
export function parseStringNumber(
  object: { [key: string]: unknown },
  fieldNames: Array<string>,
  toString: boolean
): { [key: string]: unknown } {
  for (const key in object) {
    if (fieldNames.includes(key)) {
      object[key] = toString ? `${object[key]}` : +(<string>object[key]);
    }
  }
  return object;
}

/**
 * @desc 乘法函数，用来解决浮点数乘法精度丢失问题
 * @param {Number} arg1 乘数1
 * @param {Number} arg2 乘数2
 * @return {Number} arg1和arg2的乘后的值
 */
export function multiply(arg1: number, arg2: number): number {
  // m:小数位的长度，s1:arg1转字符串,s2:arg2转字符串
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {
    m += 0;
  }
  try {
    m += s2.split('.')[1].length;
  } catch (e) {
    m += 0;
  }
  // 去除小数点后相乘，除一个10的m次方（相当于将小数点前移m位）
  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
}

export function formatPrice(price: number, currency = '￥'): string {
  if (!price && price !== 0) return '';
  return `${currency} ${(price / 100).toFixed(2)}`;
}

// 随机字符
export function ranString(len = 32): string {
  const $chars =
    'abcdefghijklmnopqrstuvwxyz1234567890'; /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1*** */
  const maxPos = $chars.length;
  let ranstr = '';
  for (let i = 0; i < len; i++) {
    ranstr += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return ranstr;
}

// 转义正则特殊字符
export function escapeRegex(value: string): string {
  // eslint-disable-next-line no-useless-escape
  return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
}

// 字符串转16进制
export const strToHexCharCode = (str: string) => {
  if (str === '') {
    return '';
  }
  const hexCharCode = [];
  hexCharCode.push('0x');
  for (let i = 0; i < str.length; i++) {
    hexCharCode.push(str.charCodeAt(i).toString(16));
  }
  return hexCharCode.join('');
};

export const getImageInfo = (str: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'Anonymous');
    image.onload = () => {
      resolve(image);
    };
    image.onerror = () => {
      reject();
    };
    image.src = str;
  });
};

/**
 * 获取文件url后缀
 */
export const getFileSuffixByUrl = (url: string) => {
  if (!url) return '';
  const suffix = url.substring(url.lastIndexOf('.') + 1).split('?')[0];
  return suffix;
};

/**
 * 获取文件MIME type
 */
export const getFileMimeTypeByUrl = (url: string) => {
  const suffix = getFileSuffixByUrl(url);
  return suffixToMimeMap[suffix] || 'image/png';
};

// 获取窗口大小
export const getWindowSize = () => {
  const width =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return {
    width,
    height,
  };
};

// 是否是PC
export function isPC() {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return false; //手机
  }

  return true;
}
