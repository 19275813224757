import { pickBy } from 'lodash';
// 浏览器相关
export default {
  commonTDKConfig: {
    default: {
      title: '拍博士证件照官网- 在线证件照制作_AI写真API_证件照换底色_照片去水印',
      keywords:
        '证件照，拍博士证件照，证件照随拍，证件照API，AI写真，智能抠图，图片去水印，证件照接口，证件照软件，证件照制作',
      description:
        '拍博士证件照（证件照随拍）支持自动抠图，智能填充更换底色，一键美颜和换装，美颜效果清晰自然。证件照规格丰富，满足你在大多数证件照使用场景的需求。支持全国邮寄,足不出户即可拿到一张证件照',
    },
    idphoto: {
      title: '拍博士证件照- 在线证件照制作_一寸证件照_证件照换底色_简历照片',
      description:
        '拍博士证件照（证件照随拍）支持自动抠图，智能填充更换底色，一键美颜和换装，美颜效果清晰自然。证件照规格丰富，满足你在大多数证件照使用场景的需求。支持全国邮寄,足不出户即可拿到一张证件照',
      keywords: '证件照制作，拍博士证件照，证件照换底色，一寸证件照，美颜证件照，电子证件照',
    },
    idphoto_api: {
      title: '拍博士API- 证件照API_AI写真_职场形象照_证件照美颜换装',
      description:
        '拍博士证件照基于AI深度学习大模型，精准对识别人脸，自动去除复杂背景，一键按照选择的证件照规格快速裁剪，同时支持更换各类背景色，轻松制作证件照。AI写真为您提供定制化数字分身的算法服务，轻松串接我们的AI API，在线体验影楼级写真大片。',
      keywords:
        'AI写真，证件照API，一键换脸，ai换脸，证件照美颜，证件照换装，拍博士API接口，人脸检测',
    },

    cutout: {
      title: '在线抠图工具- 一键抠图_抠图神器_在线去除背景图片_AI智能3秒出图',
      description:
        '拍博士在线抠图工具是一款AI智能在线抠图工具,一键自动抠图,无需ps基础,就能去除背景获得高质量透明图像,免费体验,简单快捷，适用于电商、营销设计、日常生活、办公等各类场景，满足日常图片编辑需求，功能强大的一站式作图工具。',
      keywords:
        '在线抠图，一键自动抠图，ps抠图，抠图软件，人像抠图，物品抠图，证件照换背景，智能抠图',
    },
    enhance: {
      title: '图片清晰度修复- AI智能处理_还原高清画质_低像素图片高清修复',
      description:
        '强大的AI图像处理技术，一键修复清晰度，还原高清画质，轻松修复老照片，利用先进的智能修复模糊图片技术，只需一键操作，无需等待，即刻体验模糊照片变清晰，重现美好瞬间。',
      keywords: '照片修复，图片修复，图片变清晰，图片高清修复，老照片修复，照片一键修复',
    },
    watermark: {
      title: '在线去水印- 一键智能消除改图_去除图片文字水印_拍博士AI消除',
      description:
        '拍博士抠图AI消除，不仅自动去除图片中的水印和遮挡，而且可以消除照片中的路人或其他多余主体。拍博士AI消除快速地改善照片的质量，兼容常见图片格式，一键式无痕去水印，适用于任何元素擦除，可自动填充被擦除区域遮挡的背景，效果真实自然。',
      keywords: 'AI消除，一键去水印，图片去水印，在线去水印，去除水印，魔法笔，智能涂抹',
    },
  },
  setHtmlSeo(data: { title?: string; keywords?: string; description?: string }) {
    const finalData = Object.assign(
      {},
      this.commonTDKConfig.default,
      pickBy(data, (value) => value)
    );
    document.title = finalData.title;
    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const descriptionMeta = document.querySelector('meta[name="description"]');
    keywordsMeta && keywordsMeta.setAttribute('content', finalData.keywords);
    descriptionMeta && descriptionMeta.setAttribute('content', finalData.description);
  },
};
