import {
  IUserLoginData,
  ILoginFromVerifyCodeParmas,
  IBindPhoneParams,
  ILoginFromPassword,
  IAccountRegister,
  IResetPassword,
  IChangePassword,
} from '@/types/user';
import { IUserInfo } from '@/store/modules/user/user-type';
import { AxiosRequestConfig } from '@/types/shims.axios';

import http from '@/utils/http';

export default {
  sendVerifyCode(mobile_phone: string, action: number): Promise<void> {
    return http.post('/sys/verify_code_get', { mobile_phone: mobile_phone, action });
  },

  // 获取绑定二维码，1：登录，2：绑定
  getBindWxQrcode(action: number): Promise<{ qrcode_url: string; code: string }> {
    return http.post('/sys/wxmp_qrcode_get', { action });
  },
  // 获取绑定微信结果
  getBindWxResult(code: string): Promise<{ openid: string; user_id: number }> {
    return http.post('/sys/wxopenid_get', { code });
  },

  // 验证码登录
  loginFromVerifyCode(params: ILoginFromVerifyCodeParmas): Promise<IUserLoginData> {
    return http.post('/sys/verify_code_login', params);
  },
  //
  loginFromWxOpenid(params: {
    wx_openid: string;
    visitor_user_id?: number;
  }): Promise<IUserLoginData> {
    return http.post('/sys/wxopenid_login', params);
  },

  // 微信绑定手机
  bindPhone(params: IBindPhoneParams): Promise<IUserLoginData> {
    return http.post('/sys/phone_binding', params);
  },

  // 账号绑定微信
  accountBindWx(openid: string, options?: AxiosRequestConfig) {
    return http.post('/wxopenid_binding', { wx_openid: openid }, options);
  },

  loginFromPassword(params: ILoginFromPassword): Promise<IUserLoginData> {
    return http.post('/sys/phone_passwd_login', params);
  },
  phoneRegister(params: IAccountRegister): Promise<IUserLoginData> {
    return http.post('/sys/phone_register', params);
  },

  resetPassword(params: IResetPassword): Promise<void> {
    return http.post('/sys/passwd_reset', params);
  },

  changePassword(params: IChangePassword): Promise<void> {
    return http.post('/passwd_modify', params);
  },

  userLogout() {
    return http.post('/user_loginout', {});
  },

  getUserInfo(): Promise<IUserInfo> {
    return http.post('/user_query', {});
  },

  getRechargeQrcode(params: {
    pay_type: number;
    recharge_type: number;
  }): Promise<{ qrcode_url: string; payment_id: number }> {
    return http.post('/user_recharge', params);
  },
  // 获取单次支付二维码
  getOrderPayQrcode(params: {
    order_id: number;
    pay_type_id: number;
    pay_method_id: number;
  }): Promise<{ qrcode_url: string; payment_id: number }> {
    return http.post('/order_pay', params);
  },

  getRechargeResult(payment_id: number): Promise<{ status: number }> {
    return http.post('/user_recharge_query', { payment_id });
  },

  // 匿名登陆
  loginForAnonym(): Promise<IUserLoginData> {
    return http.post('/sys/visitor_login', {});
  },
};
