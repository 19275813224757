import { GetterTree } from 'vuex';
import { IUserState } from './user-type';
import { RootState } from '../../index-type';
import dayjs from 'dayjs';

export const getters: GetterTree<IUserState, RootState> = {
  isLogin(state): boolean {
    const { token } = state;
    return Boolean(token);
  },

  // 是否绑定了手机号
  isBindPhone(state) {
    return Boolean(state.userInfo?.mobile_phone);
  },

  // 月点数是否过期
  isMonthExpire(state) {
    if (!state.userInfo?.expire_time) return true;

    if (dayjs().isAfter(state.userInfo?.expire_time)) {
      // 已过期
      return true;
    }
    return false;
  },
};
