/* eslint-disable */
import { createApp, ComponentPublicInstance, ComponentOptionsBase, App, createVNode } from 'vue';
import instanceComponent from './pay-confirm.vue';
import type { RechargeDialogSinglePayInfo } from '@/store/modules/global/global-type';

const obj: {
  instance?: ComponentPublicInstance<
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  false,
  ComponentOptionsBase<any, any, any, any, any, any, any, any, any, {}>>;
  app?: App<Element>;
  root?: HTMLDivElement;
  unmountInstance(): void;
  initInstance(payPoint: number, category_id: number, singlePayInfo: RechargeDialogSinglePayInfo, resolve: any, reject: any): void;
  show(payPoint: number, category_id: number, singlePayInfo: RechargeDialogSinglePayInfo): Promise<{isSinglePaySuccess: boolean}>;
} = {
  instance: undefined,
  app: undefined,
  root: undefined,
  unmountInstance() {
    if (this.app) this.app.unmount();
    if (this.root) document.body.removeChild(this.root);
    this.app = undefined
    this.root = undefined
  },
  initInstance(payPoint, category_id,singlePayInfo, resolve, reject) {
    this.app = createApp(instanceComponent, {resolve, reject, payPoint, category_id, singlePayInfo});
    this.root = document.createElement('div');
    document.body.appendChild(this.root);
    this.instance = this.app.mount(this.root);
  },

  show(payPoint, category_id, singlePayInfo) {
    this.unmountInstance()
    return new Promise((resolve, reject) => {
      this.initInstance(payPoint, category_id, singlePayInfo, resolve, reject)
    })
  },
}

export default obj
